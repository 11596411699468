<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Registration requests</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        &nbsp;
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        &nbsp;
      </el-col>
    </el-row>
    <Table
      tableKey="traderFunds"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :showSummary="false"
      :tableActionShowVisibility="false"
      :tableActionVisibility="false"
      :tableLoadingStatus.sync="loading"
      :tablePagination="tablePagination"
      @pagination="handlePagination()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import { fetchList } from "@/api/super-admin/request-registration";


export default {
  name: "ListUserRegistrations",
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      listDataTemp: null,
      config: [
        {
          label: "Name",
          prop: "name",
          width: "150",
          className: "blueFont",
        },
        {
          label: "Email",
          prop: "email",
          width: "150",
          className: "blueFont",
        },
        {
          label: "Phone #",
          prop: "phone",
          width: "110",
          className: "blueFont",
        },
        {
          label: "City",
          prop: "city",
          width: "120",
          className: "blueFont",
        },
        {
          label: "State",
          prop: "state",
          width: "100",
          className: "blueFont",
        },
        {
          label: "Country",
          prop: "country",
          width: "90",
          className: "blueFont",
        },
        {
          label: "Created At",
          prop: "createdAt",
          width: "140",
          className: "blueFont",
        },
        {
          label: "Message #",
          prop: "message",
          width: "500",
          className: "blueFont",
        },
        
      ],
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      listData: {
        data: {
          item: [],
        },
      },
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.listData.data.item = response.data.data.data;
        this.tablePagination = response.data.data.meta;
        this.loading = false;
      })
    },
  },
};
</script>
